import React, { useState, useEffect } from "react";
import axios from "../../interceptors/interceptors";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import CircularProgress from "@mui/material/CircularProgress";
import { logEventHub } from "./utils";
import "../../stylesheets/triagebot/ProfileSelect.scss";

const profileURL = process.env.REACT_APP_PROFILES_URL;

const ProfileSelect = ({ classes, profileChanged }) => {
  const [loadState, setLoadState] = useState("LOADING");
  const [profiles, setProfiles] = useState([]);
  const [selected, setSelected] = useState({
    name: "Loading Profiles",
    id: ""
  });
  const [open, setOpen] = useState(false);

  if (typeof profileChanged !== "function") profileChanged = () => { };

  useEffect(() => {
    const getProfiles = () => {

      axios({
        method: "post",
        url: profileURL,
        timeout: 0,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((response) => {
  
        setProfiles(response.data);
        if (response.data && response.data[0].name) {
          setSelected(response.data[0]);
          profileChanged(response.data[0]);
        }
        setLoadState("LOADED");
  
      }).catch((error) => {
  
        logEventHub('Error loading mock profile data.', 'FE - profile select');
  
        console.warn("Error Loading Profiles. Using profiles from environment variable.")
  
        const profiles = JSON.parse(process.env.REACT_APP_PROFILES || '[{ "name": "Error", "id": "" }]');
  
        setProfiles(profiles);
        setSelected(profiles[0]);
        profileChanged(profiles[0]);
  
        setLoadState("LOADED");
      })
  
    }

    if (!profiles.length) getProfiles();
  }, [profiles.length, profileChanged]);

  const selectProfile = (profile) => {
    setOpen(false);
    setSelected(profile);
    profileChanged(profile);
  };

  return (
    <div className={classes + " profiles-select"}>
      {open && (
        <div
          onClick={() => {
            setOpen(false);
          }}
          className="click-catcher"
        ></div>
      )}
      <button
        className={open ? "open" : ""}
        onClick={() => {
          setOpen(!open);
          document.activeElement.blur();
        }}
      >
        {selected.name}
        <KeyboardArrowDown style={{ marginRight: "-10px" }} />
      </button>
      {open && (
        <ul className="profiles-dropdown">
          {loadState === "LOADING" ? (
            <li className="loading">
              <CircularProgress />
            </li>
          ) : (
            profiles.map((profile, index) => {
              return (
                <li key={"profile" + index}>
                  <button
                    className="profile"
                    onClick={() => {
                      selectProfile(profile);
                    }}
                  >
                    {profile.name}
                  </button>
                </li>
              );
            })
          )}
        </ul>
      )}
    </div>
  );
};

export default ProfileSelect;
