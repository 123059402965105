import React, { useEffect, useRef, useState } from 'react';
import { getMarkDown, addDetectUserEvents, removeDetectUserEvents, useDetectMobile } from '../utils';
import { ReactComponent as ConfirmIcon } from '../../../assets/confirm.svg';
import { ReactComponent as ScheduleIcon } from '../../../assets/schedule.svg';
import { ReactComponent as PcpIcon } from '../../../assets/person_24dp.svg';
import { ReactComponent as DateIcon } from '../../../assets/event.svg';
import { ReactComponent as PlaceIcon } from '../../../assets/place.svg';
import { ReactComponent as ComputerIcon } from '../../../assets/computer.svg';
import '../../../stylesheets/triagebot/Confirm.scss';
import UrgentLocationToast from '../dialogs/UrgentLocationToast';
import ChangeOverDialog from '../dialogs/ChangeOverDialog';

//********************************************************* */

const ConfirmMessage = ({ message, supressState, supressNPS, setToastMessage }) => {
    const [showEVisitChangeOverPromptDialog, setShowEvisitChangeOverPromptDialog] = useState(false);
    var timer = null;

    const headerText = (message.data && message.data.length && message.data[0].headerText)
        ? message.data[0].headerText
        : '';

    const subHeaderText = (message.data && message.data.length && message.data[0].subHeaderText)
        ? message.data[0].subHeaderText
        : '';
    
    const { isMobile } = useDetectMobile();
    const contactNum = subHeaderText?.match(/(?:[-]*\d){10,13}/g);
    const contactNumText = (contactNum && contactNum.length > 0 ) ? `tel:+1${contactNum[0]}` : '';
    const subHeaderSplitText = subHeaderText?.split(contactNum?.[0]);

    if(subHeaderSplitText?.length > 1 && (subHeaderSplitText[1].charAt(0) === '.')){
        subHeaderSplitText[1] = getMarkDown(subHeaderSplitText[1].substring(1));
        subHeaderSplitText[0] = getMarkDown(subHeaderSplitText[0]);
        contactNum[0] = contactNum?.[0]+'.';
    }

    const checkMark = message?.data?.[2]?.showCheckMark;
    const borderColor = message?.data?.[2]?.sideBarColor || message?.data?.[1]?.sideBarColor;

    const handleTimerOut = () => {
        //sendMessage('message', 'NPS Timeout', 'NPS Timeout');
        removeDetectUserEvents(resetTimer);
    }

    const resetTimer = () => {
        timer && clearTimeout(timer);

        timer = setTimeout(handleTimerOut, 5000);
    }

    const messageRef = useRef();

    useEffect(() => {
        supressState(true)
        
        if (messageRef.current) {
            messageRef.current.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'start'
                })
        }

        if(supressNPS) return;

        resetTimer();
        addDetectUserEvents(resetTimer);

        return () => {
            clearTimeout(timer);
            removeDetectUserEvents(resetTimer);
        }
    }, [])

    const LocationToastLinkText = message?.data?.[1]?.urgentCareLocationLinkText;
    const LinkContent = message?.data?.[1]?.urgentCareLocationLinkContent;
    const LocationToastDetail = LinkContent?.locations;
    const LocationDisclaimerText = message?.data?.[1]?.urgentCareLocationDisclaimerText;

    const date = message?.data?.[1]?.date;
    const time = message?.data?.[1]?.time;
    const pcp = message?.data?.[1]?.pcp;
    const place = message?.data?.[1]?.address;
    const department = message?.data?.[1]?.department;
    const phoneNumber = message?.data?.[1]?.phone;

    const departmentNum = department?.match(/(?:[-]*\d){10,13}/g)?.[0];
    const deptNumText = departmentNum  ? `tel:+1${departmentNum}` : '';
    const deptSplitText = department?.split(departmentNum);

    const changeOverPromptDialogEVisit = message?.data?.[1]?.changeoverPrompt;

    const dialogResponse = (response) => {
        if (response) {
            console.log("Opening E-Visit in new window")
            return;
        }
    }

    useEffect(() => {
        if(changeOverPromptDialogEVisit && changeOverPromptDialogEVisit?.data?.[1]?.externalSchedulingUrl){
            setShowEvisitChangeOverPromptDialog(true);
        }
    }, []);
    
    return (
        <div className='confirm card' style={{borderLeft: `4px solid ${borderColor}`}} ref={messageRef}>
            {
                showEVisitChangeOverPromptDialog
                ?
                    <ChangeOverDialog
                        message={changeOverPromptDialogEVisit}
                        setShowChangeOverPromptDialog={setShowEvisitChangeOverPromptDialog}
                        isAccepted={dialogResponse}
                    />
                :
                    <></>
            }
            {
            checkMark ?
                <div className='CheckIcon'>
                    <p>
                        <ConfirmIcon />
                    </p>
                </div>
                :
                <></>
            }
            <div className='body' style={checkMark ? { paddingLeft: '16px' } : { paddingLeft: '0px' }}>
                <div className='header'>
                    <h2 className='headerText'>{getMarkDown(headerText)}</h2>
                </div>
                {
                    (isMobile && subHeaderSplitText && subHeaderSplitText.length === 2)?
                        <div className='subHeaderText'>                            
                            {subHeaderSplitText[0]}<a className = "contactNumTag" target="_blank" href={contactNumText}>{contactNum?.[0]}</a>{subHeaderSplitText[1]} 
                        </div> :
                        <div className='subHeaderText'>
                            {getMarkDown(subHeaderText)}
                        </div>
                }

                {
                    LocationToastLinkText 
                    ?
                    <div 
                        className='LocationLinkText'
                        onClick={() =>
                            setToastMessage({
                            type: "locations",
                            header: LinkContent.header,
                            subheader: LocationDisclaimerText,
                            body: (
                                <UrgentLocationToast
                                message={LocationToastDetail}
                                />
                            ),
                            })
                        }
                    >
                        {getMarkDown(LocationToastLinkText)}
                    </div>
                    :
                        <></>
                }

                {
                    (date || time || pcp || place)
                    ?
                        <>
                            <div className='divider-line'></div>

                            {
                                date?
                                    <div className='date combine'>
                                        <DateIcon className='icon' />
                                        <div className='date'>{date}</div>
                                    </div>
                                :
                                    <></>
                            }

                            {
                                time ?
                                    <div className='time combine'>
                                        <ScheduleIcon className='icon'/>
                                        <div className='time'>{time}</div>
                                    </div>
                                :
                                    <></>
                            }

                            {
                                pcp? 
                                    <div className='PCP combine'>
                                        <PcpIcon className='icon'/>
                                        <div className='pcp'>{pcp}</div>
                                    </div>
                                :
                                    <></>
                            }

                            {
                                department?
                                    <div className='dept combine'>
                                        <ComputerIcon className='icon'/>
                                        {
                                            isMobile && departmentNum && deptSplitText?.length > 0?
                                            <div className='Dept'>{getMarkDown(deptSplitText?.[0])}<a target="_blank" href={deptNumText}>{departmentNum}</a></div>
                                            :<div className='Dept'>{getMarkDown(department)}</div>
                                        }
                                    </div>
                                :
                                    <></>
                            }

                            {
                                place?
                                    <div className='Place combine'>
                                        <PlaceIcon className='icon' />
                                        <div className='place'> {getMarkDown(place)} </div>
                                    </div>
                                :
                                    <></>
                            }

                            {
                                phoneNumber ?
                                    <div className='phone combine'>
                                        {
                                            isMobile ?
                                            <a className='phone' target="_blank" href={`tel:+1${phoneNumber}`}>{getMarkDown(phoneNumber)}</a>
                                                : <div className='phone'>{getMarkDown(phoneNumber)}</div>
                                        }
                                    </div>
                                    :
                                    <></>
                            }
                        </>
                    :
                        <></>
                }
            </div>
        </div>
    );
}

export default ConfirmMessage;