import React, { useEffect, useRef, useState, } from "react";
import Zippy from './Zippy';
import TimePicker from './TimePicker';
import Footer from './Footer';
import { getMarkDown, useDetectMobile } from '../utils';
import '../../../stylesheets/triagebot/AppointmentPicker.scss';
import { fontStyle } from "@mui/system";
import polyfill from 'scroll-polyfill';

//************************* */

const MultiAppointmentPickerMessage = ({ message, sendMessage, disabled }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [openedZippy, setOpenedZippy] = useState(0);
    const [showAll, setShowAll] = useState(false);

    polyfill();

    const [hasError, setHasError] = useState(
        (message && message.data && message.data.length && message.data[0].errorInScheduling)
            ? message.data[0].errorInScheduling
            : false)

    if (!(sendMessage instanceof Function))
        sendMessage = () => { };

    const isDisabled = disabled || submitted;

    const isValid = selectedTime;

    const { isMobile } = useDetectMobile();

    const Provider = {
        header : message?.data?.[1]?.provider?.headerText,
        Slots : message?.data?.[1]?.provider?.allSlots
    }

    const Department = {
        header : message?.data?.[1]?.department?.headerText,
        Slots : message?.data?.[1]?.department?.allSlots
    }

    const aemMeridiem = {
        All : message?.data?.[1]?.amPmTexts?.allText,
        AM  : message?.data?.[1]?.amPmTexts?.amText,
        PM  : message?.data?.[1]?.amPmTexts?.pmText
    }

    const requestDeptScheduleText = message?.data?.[1]?.requestDeptScheduleText;
    const requestProviderScheduleText = message?.data?.[1]?.requestProviderScheduleText;
    const contactProviderOfficeText = message?.data?.[1]?.contactProviderOfficeText;
     
    const slotFormat = message?.data?.[1]?.slotFormat ? message?.data?.[1]?.slotFormat : '';

    const cdoname = message?.data?.[0]?.cdoName;
    const providerLocation = message?.data?.[1]?.providerLocation ? message?.data?.[1]?.providerLocation : '';

    const [Header, setHeader] = useState('');
    const [Slots, setSlots] = useState([]);
    const [showDept, setShowDept] = useState(false);
    const [providerName, setProviderName] = useState('');
    const messageRef = useRef(null);

    useEffect(()=>{
        if( Provider.Slots.length > 0 && Department.Slots.length >= 0 ){
            setSlots(Provider.Slots);
            setHeader(Provider.header);
            setShowDept(false);
        }
        else if(Provider.Slots.length===0 && Department.Slots.length>0){
            setSlots(Department.Slots);
            setHeader(Department.header);
            setShowDept(true);
        }
    }, [])

    useEffect(()=>{
        showDept 
            ? 
                setDepartment() 
            :
                setProvider()
    }, [showDept]);

    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView(
                {
                    block: 'center',
                    behavior: 'smooth',
                    inline: 'center'
                })
        }
    });

    const setDepartment = () => {
        setHeader(Department.header)
        setSlots(Department.Slots)
    }

    const setProvider = () => {
        setHeader(Provider.header);
        setSlots(Provider.Slots);
    } 

    const subHeader = (message.data && message.data.length)
        ? message.data[0].subHeaderText
        : ''

    const errorMessage = (message && message.data && message.data.length && message.data[0].errorMessage)
        ? message.data[0].errorMessage
        : 'Error'
    
    var slotsArray = [];

    for(let i=0;i<Slots.length;i++){
        slotsArray.push(Slots[i]);
    }

    const slotsDate = slotsArray
        .filter((value) => { return Number.isInteger(value.appointmentTime) })
        .map((millisec) => { return new Date(millisec.appointmentTime) })

    const uniqueTitleDates = [...new Set(slotsDate.map((date) => { return getDateString(date) }))]

    const validDates = uniqueTitleDates.map((titleDate) => {
        return {
            displayDate: titleDate,
            times: slotsDate.filter((date) => { return getDateString(date) === titleDate })
        }
    })


    const newset = [];
    validDates.forEach((vdata) => {
        newset.push(vdata.displayDate)
    })
    
    newset.sort((date1, date2) => {  
        const dateObj1 = new Date(date1);  
        const dateObj2 = new Date(date2);  
        if (dateObj1 < dateObj2) {  
          return -1;  
        } else if (dateObj1 > dateObj2) {  
          return 1;  
        } else {  
          return 0;  
        }  
      });  
    

    const sortedDisplaydate = newset.map((a) => {
        return {
            displayDate: a,
            times: slotsDate.filter((date) => { return getDateString(date) === a })
        }
    })

    const getFilteredTimes = (times) => {
        switch (selectedFilter) {
            case 'am': return times.filter((times) => { return times.getUTCHours() < 12 });
            case 'pm': return times.filter((times) => { return times.getUTCHours() >= 12 });
            default: return times;
        }
    };

    const getTitle = (uniqueDate, count) => {
        return (
            <span className='title'>
                <span>
                    {uniqueDate}
                </span>
                <span>
                    {count}
                    {isMobile || ' available'}
                </span>
            </span>
        )
    }

    const toggleZippies = (zippy) => {
        setOpenedZippy(zippy);
    }

    const setSelectedDateTime = (date, time) => {
        setSelectedDate(date);
        setSelectedTime(time);
        setHasError(false);
    }

    const cancelPress = () => {
        sendMessage('rewind', ['summaryPlaceHolder', 'emergency'], ['summaryPlaceHolder', 'emergency']);

        sendMessage('message', 'returnerr', 'returnerr');
    }

    const submitPress = (event, button) => {
        if (submitted) return;

        setSubmitted(true);

        var result = Slots.filter((value) => value.appointmentTime === selectedTime)[0];

        sendMessage('message', JSON.stringify(result), result);
    }

    function getDateString(date) {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        const month = months[date.getUTCMonth()];
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();

        return `${month} ${day}, ${year}`;
    }

    const handleChangeOutline = (value) => {
        setSelectedFilter(value);

        document.activeElement.blur();
    }

    const callProviderMessage = message?.data?.[1]?.callProviderMessage;

    const providerNum = callProviderMessage?.match(/(?:[-]*\d){10,13}/g);
    const providerNumText = (providerNum && providerNum.length > 0 ) ? `tel:+1${providerNum[0]}` : '';
    const providerNumSplitText = callProviderMessage?.split(providerNum?.[0]);

    return (
        <div className={(isDisabled ? 'disabled ' : '') + (isMobile ? 'mobile ' : '') + 'desktop apt-picker card'} ref={messageRef}>
            <div className='contentMultiAppointment'>
                <div className='header'>
                    <h2 className="headerText">{getMarkDown(Header)}</h2>
                    <div className='subheader'>
                        {getMarkDown(subHeader)}
                    </div>
                </div>
                {
                    !hasError ||
                    <div className='alert'>
                        {errorMessage}
                    </div>
                }
                <div className='body'>

                    <div className='filter-bar'>
                        <button
                            className={selectedFilter === 'am' ? 'selected' : ''}
                            onClick={() => handleChangeOutline('am')}
                            disabled={isDisabled}
                        >
                            {aemMeridiem.AM}
                        </button>
                        <button
                            className={selectedFilter === 'pm' ? 'selected' : ''}
                            onClick={() => handleChangeOutline('pm')}
                            disabled={isDisabled}
                        >
                            {aemMeridiem.PM}
                        </button>
                        <button
                            className={selectedFilter === 'all' ? 'selected' : ''}
                            onClick={() => handleChangeOutline('all')}
                            disabled={isDisabled}
                        >
                            {aemMeridiem.All}
                        </button>
                    </div>

                    {
                        sortedDisplaydate.map((date, i) => {
                            const filteredTimes = getFilteredTimes(date.times);
                            return (
                                <Zippy
                                    title={getTitle(date.displayDate, filteredTimes.length)}
                                    open={(openedZippy === i) && filteredTimes.length && !isDisabled}
                                    onClick={() => {toggleZippies(openedZippy === i ? -1 : i); setShowAll(false); }}
                                    disabled={!filteredTimes.length || isDisabled}
                                    key={date + i}
                                >
                                    <TimePicker
                                        displayTimes={filteredTimes}
                                        selectedTime={(selectedDate === date.titleDate) ? selectedTime : null}
                                        setSelectedTime={(time) => setSelectedDateTime(date.titleDate, time)}
                                        meridiem={selectedFilter}
                                        isMobile={isMobile}
                                        showAll={showAll}
                                        setShowAll={setShowAll}
                                        disabled={isDisabled}
                                        providerInfo={showDept ? Department.Slots : Provider.Slots}
                                        showDept={showDept}
                                        aemMeridiem={aemMeridiem}
                                        slotFormat={slotFormat}
                                        currentDate={new Date(date.displayDate).getDate()}
                                        setProviderName={(provider) => setProviderName(provider)}
                                        ProviderName={providerName}
                                        providerLocation={providerLocation}
                                        cdoname={cdoname}
                                    />
                                </Zippy>
                            )
                        })
                    }

                    {
                        (requestDeptScheduleText && requestProviderScheduleText && !showDept)
                        ?            
                        <div className={ (Department.Slots.length > 0 && Provider.Slots.length > 0 ) ? "nonPcpText" : "" } onClick={ () => setShowDept(!showDept) }>
                                {requestDeptScheduleText}
                        </div>
                        :
                        <></>
                    }

                    {
                        (requestDeptScheduleText && requestProviderScheduleText && showDept)
                        ?
                        <>
                            <div className={ (Department.Slots.length > 0 && Provider.Slots.length > 0 ) ? "nonPcpText" : "" } onClick={ () => setShowDept(!showDept) }>
                                {requestProviderScheduleText}
                            </div>

                            <div className="contactProviderText">
                                {getMarkDown(contactProviderOfficeText)}
                            </div>
                            </>
                        :
                        <></> 
                    }


                    {
                        callProviderMessage && ((Provider.Slots.length === 0 && Department.Slots.length > 0) || (Provider.Slots.length > 0 && Department.Slots.length === 0))
                            ?
                            isMobile && (providerNumSplitText?.length === 2) ?
                                <div className='callProviderMessage' >
                                    {providerNumSplitText[0]}<a className="contactNumTag" target="_blank" href={providerNumText}>{providerNum?.[0]}</a>{providerNumSplitText[1]}
                                </div>
                                :
                                <div className='callProviderMessage' >
                                    {callProviderMessage}
                                </div>
                            :
                            <></>
                    }

                </div>
            </div>
            { submitted ||
                <Footer>
                    <button
                        onClick={cancelPress}
                        className={isMobile ? 'mobile cancel-button' : 'cancel-button'}
                        disabled={isDisabled}
                    >
                        Cancel
                </button>
                    <button
                        onClick={submitPress}
                        className='continue-button'
                        disabled={!isValid || isDisabled}
                    >
                        Confirm
                </button>
                </Footer>
            }
        </div>
    );
}

export default MultiAppointmentPickerMessage;